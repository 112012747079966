import { Inject, Injectable } from '@angular/core';
import { ReferenceApiService } from '@fleet/api';
import {
  ApiResponse,
  ReferenceDataItemModel,
  ReferenceDataModel,
} from '@fleet/model';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class ReferenceService {
  reference: BehaviorSubject<ReferenceDataModel> = new BehaviorSubject(null);
  fleetProduct: string;
  constructor(
    private referenceApiService: ReferenceApiService,
    @Inject('env') env: any
  ) {
    this.fleetProduct = env.fleetProduct;
  }

  searchReferences(fields: any) {
    this.referenceApiService.getReferenceData(fields).subscribe(
      (resp: ApiResponse<ReferenceDataModel> | any) => {
        this.reference.next(resp.data);
      },
      (error: any) => {}
    );
  }

  get references$() {
    return this.reference.asObservable();
  }

  get driverDocumentTypes$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference.driverDocumentTypes;
      })
    );
  }

  get vehicleDocumentTypes$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return null;
      })
    );
  }

  get vehicleRegistrationDocumentTypes$() {
    return this.reference.asObservable().pipe(
      map((reference: any) => {
        return reference.vehicleRegistrationDocumentTypes;
      })
    );
  }

  get vehicleAuthorityDocumentTypes$() {
    return this.reference.asObservable().pipe(
      map((reference: any) => {
        return reference.vehicleAuthorityDocumentTypes;
      })
    );
  }

  get lifeCycleStages$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.lifecycleStages : null;
      })
    );
  }

  get alertSeverities$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.alertSeverities : null;
      })
    );
  }

  get productTypes$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.productTypes : null;
      })
    );
  }

  get channelTypes$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.channelTypes : null;
      })
    );
  }

  get alertStatuses$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.alertStatuses : null;
      })
    );
  }

  get alertTypes$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.alertTypes : null;
      })
    );
  }

  get blockActions$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.blockActions : null;
      })
    );
  }

  get blockReasons$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.blockReasons : null;
      })
    );
  }

  get networkCancellationReasons$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        //TEMP HACK FOR BUSINESS PORTAL
        let reasons = <any>[];
        if (reference) {
          if (this.fleetProduct === 'BUSINESS') {
            reasons = [
              {
                displayValue: 'No longer required',
                code: 'NO_LONGER_REQUIRED',
              } as ReferenceDataItemModel,
            ];
          } else {
            reasons = reference.networkCancellationReasons;
          }
        }

        return reasons;
      })
    );
  }

  get networkUserStatuses$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return []; //reference ? reference.networkUserStatuses : null;
      })
    );
  }

  get operatorStatuses$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.operatorStatuses : null;
      })
    );
  }

  get promotionTypes$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.promotionTypes : null;
      })
    );
  }
  // get fareElementGroups$() {
  //   return this.reference.asObservable().pipe(
  //     map((reference: ReferenceDataModel) => {
  //       return reference ? reference.fareElementGroups : null;
  //     })
  //   );
  // }

  get promotionStatuses$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.promotionStatuses : null;
      })
    );
  }

  get promotionComponentTypes$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.promotionComponentTypes : null;
      })
    );
  }

  get promotionEventTypes$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.promotionEventTypes : null;
      })
    );
  }
  // get fareElementTypes$() {
  //   return this.reference.asObservable().pipe(
  //     map((reference: ReferenceDataModel) => {
  //       return reference ? reference.fareElementTypes : null;
  //     })
  //   );
  // }

  get operatorCreditTerms$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.operatorCreditTerms : null;
      })
    );
  }

  get organisationCreditTerms$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.organisationCreditTerms : null;
      })
    );
  }

  get blockTypes$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.blockTypes : null;
      })
    );
  }

  get driverAdjustmentTypes$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.driverAdjustmentTypes : null;
      })
    );
  }

  get organisationAdjustmentTypes$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.organisationAdjustmentTypes : null;
      })
    );
  }

  get localities$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.localities : null;
      })
    );
  }

  get ticketCategoryGroups$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.ticketCategoryGroups : null;
      })
    );
  }
  get assetTypes$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.assetTypes : null;
      })
    );
  }

  get associationTypes$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.associationTypes : null;
      })
    );
  }

  get integrationLinkTypes$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.integrationLinkTypes : null;
      })
    );
  }

  get integrationLinkStatuses$() {
    return this.reference.asObservable().pipe(
      map((reference: ReferenceDataModel) => {
        return reference ? reference.integrationLinkStatuses : null;
      })
    );
  }
}
